import React, { useEffect } from "react";

import { graphql, useStaticQuery } from "gatsby";
import { useLocation } from "@gatsbyjs/reach-router";

import styled from "@emotion/styled";
import classNames from "classnames";

import colors from "../../../utils/colors";
import { activeBorderBottom } from "../activeLink";
import { blur, Blurred } from "../Blur";
import { contentContainer } from "../ContentContainer";
import MailIcon from "../icons/MailIcon";
import { Link } from "../LinkComponent";
import ShapeCorner, {
    shapeCornerDimensions,
} from "../ShapeContainer/ShapeCorner";
import TrackableContent from "../TrackableContent";
import ContactForm from "./ContactForm";
import useStore from "./ContactForm/store";
import LinkItems from "./Desktop/LinkItems";
import MobileItemsContainer from "./Mobile/MobileItemsContainer";
import MobileModal from "./Mobile/MobileItemsContainer/MobileModal";
import Phone from "./Phone";
import ShoppingCart from "./ShoppingCart";
import useNavigationStore from "./store";
import TechnicList from "./TechnicList";

const navHeight = "max(8vh, 50px)";
const logoOverhang = 0.1; // 10 %

const Wrapper = styled.div`
    .legacyPage & {
        font-family: var(--font-family-vito);
    }
`;

const Nav = styled(Blurred)`
    position: fixed;
    z-index: 110;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: calc(${navHeight});
    color: ${colors.red};
    background-color: white;

    ${blur({
        eles: ["Nav"],
        extraFilter: "drop-shadow(0 2px 4px rgb(0 0 0 / 20%))",
    })}
`;

const Container = styled.div`
    position: fixed;
    z-index: 120;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${contentContainer};
    ${({ theme }) => theme.breakpoints.from.extraLarge.css`
        padding-right: 20em;
    `}
`;

const Img = styled.img`
    align-items: center;
    width: 100%;
    height: auto;
`;

const logoShapeWidths = {
    extraSmall: "150px",
    small: "250px",
    medium: "170px",
    normal: "250px",
    large: "400px",
    extraLarge: "700px",
};

const LogoShape = styled.div`
    position: relative;
    z-index: 111;
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${100 * (1 + logoOverhang * 2)}%;
    background-color: ${colors.red};
    filter: drop-shadow(0 2px 4px rgb(0 0 0 / 50%));
    ${({ theme }) =>
        theme.breakpoints.propPerEachFrom("width", logoShapeWidths)}
`;

const LogoLink = styled(Link)`
    width: 150px;

    ${({ theme }) => theme.breakpoints.upTo.extraSmall.css`
        width: 110px;
    `}
`;

const LogoContainer = styled.div`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-start;
    ${({ theme }) =>
        theme.breakpoints.propPerEachFrom(
            "width",
            theme.breakpoints.zipWithBreakpointRecords(
                (w, p) => `calc(${w} - ${p})`
            )(logoShapeWidths, theme.paddings.containerOuter.values)
        )}

    margin-right: calc(${shapeCornerDimensions.width} / ${shapeCornerDimensions.height} * ${navHeight} * ${1 +
    logoOverhang * 2});
`;

const ShapeContainer = styled.div`
    position: absolute;
    right: 0;
    height: 100%;
    transform: scaleX(-1) scaleY(-1) translateX(-99%);

    svg {
        width: auto;
        height: 100%;
        min-height: 93px;
    }
`;

const ContactItems = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${({ theme }) => theme.breakpoints.upTo.medium.css`
        display: none;
    `}
`;

const IconContainer = styled.div`
    display: flex;
    width: 20px;
`;

const ContactFormItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 !important;
    margin-left: 30px;

    ${({ theme }) => theme.breakpoints.upTo.normal.css`
        margin-left: 15px;
    `}

    ${activeBorderBottom({ size: "4px", target: IconContainer })}

    font-weight: 900;
    color: ${colors.red};
    cursor: pointer;

    svg {
        fill: currentColor !important;
    }
`;

const Page = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: calc(${navHeight} * ${1 + logoOverhang});

    /* Pages starting with full-width images at the very top
     * shouldn't get offset by the logo overhang */
    .legacyPage & {
        padding-top: calc(${navHeight});
    }

    > * {
        flex-grow: 1;
    }
`;

const ContactFormContainer = (props) => {
    const { contactFormVisible } = useStore(({ contactFormVisible }) => ({
        contactFormVisible,
    }));

    if (!contactFormVisible) return null;
    return <ContactForm {...props} />;
};

const MobileModalContainer = (props) => {
    const { openContactForm } = useStore(({ openContactForm }) => ({
        openContactForm,
    }));
    const { mobileModalIsActive, toggleMobileModal } = useNavigationStore(
        ({ mobileModalIsActive, toggleMobileModal }) => ({
            mobileModalIsActive,
            toggleMobileModal,
        })
    );

    useEffect(() => {
        if (mobileModalIsActive) {
            document.body.style.overflow = "hidden";
            document.body.style.position = "fixed";
        } else {
            document.body.removeAttribute("style");
        }
    }, [mobileModalIsActive]);

    return (
        <MobileModal
            {...{ openContactForm }}
            modalState={mobileModalIsActive}
            handleClick={toggleMobileModal}
            {...props}
        />
    );
};

const MobileItemsContainerContainer = (props) => {
    const { mobileModalIsActive, toggleMobileModal, closeMobileModal } =
        useNavigationStore(
            ({ mobileModalIsActive, toggleMobileModal, closeMobileModal }) => ({
                mobileModalIsActive,
                toggleMobileModal,
                closeMobileModal,
            })
        );

    const location = useLocation();
    useEffect(() => {
        closeMobileModal();
    }, [closeMobileModal, location]);

    return (
        <MobileItemsContainer
            handleClick={toggleMobileModal}
            isActive={mobileModalIsActive}
            {...props}
        />
    );
};

const ContactFormItemContainer = (props) => {
    const { contactFormVisible, toggleContactForm } = useStore(
        ({ contactFormVisible, toggleContactForm }) => ({
            contactFormVisible,
            toggleContactForm,
        })
    );

    return (
        <ContactFormItem
            onClick={toggleContactForm}
            className={classNames({ active: contactFormVisible })}
            {...props}
        >
            <IconContainer>
                <MailIcon color={colors.red} hoverColor={colors.blue} />
            </IconContainer>
        </ContactFormItem>
    );
};

const Navigation = ({ nav, organization }) => (
    <>
        <TrackableContent name="navigation">
            <Wrapper>
                <Nav as="nav" eles={["Nav"]}>
                    <LogoShape>
                        <ShapeContainer>
                            <ShapeCorner color={colors.red} />
                        </ShapeContainer>
                    </LogoShape>

                    <Container>
                        <LogoContainer>
                            <LogoLink to="/">
                                <Img
                                    src={nav.logo.image.src.publicURL}
                                    alt={nav.logo.text}
                                />
                            </LogoLink>
                        </LogoContainer>

                        <LinkItems sections={nav.sections} />

                        <ContactItems>
                            <Phone
                                defaultPhoneNumber={organization.telephone}
                                telName={nav.telName}
                            />

                            <ContactFormItemContainer
                                title={nav.contactFormName}
                            />
                            <ShoppingCart />
                            <TechnicList />
                        </ContactItems>

                        <MobileItemsContainerContainer
                            {...{ organization, nav }}
                        />
                    </Container>
                </Nav>

                <MobileModalContainer sections={nav.sections} {...{ nav }} />
            </Wrapper>
        </TrackableContent>
        <ContactFormContainer />
    </>
);
export default Navigation;

export const WithNavigation = ({ children, ...props }) => (
    <>
        <Navigation {...props} />
        <Page>{children}</Page>
    </>
);

export const NavigationContainer = (props) => {
    const { nav } = useStaticQuery(graphql`
        query {
            nav: globalYaml(templateKey: { eq: "global/nav" }) {
                logo {
                    image {
                        src {
                            ...ImageSrc
                        }
                    }
                    text
                }
                telName
                contactFormName
                sections {
                    href
                    name
                    subsections {
                        name
                        items {
                            text
                            href
                        }
                    }
                }
                mobileLinks {
                    name
                    href
                    icon
                }
            }
        }
    `);

    return <Navigation {...{ nav }} {...props} />;
};

export const WithNavigationContainer = ({ children, ...props }) => (
    <>
        <NavigationContainer {...props} />
        <Page>{children}</Page>
    </>
);
